.inline-audio-player {
  background: none;
}
.inline-audio-player__smp-container {
  display: inline-block;
  width: 100%;
}
.inline-audio-player__smp-container > div {
  /* CreateSMPAudio */
  display: inline-block;
  width: 100%;
}
.inline-audio-player__smp-container > div > div {
  height: 50px;
  position: relative;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.inline-audio-player__smp-container > div > div > div {
  padding: 0 !important;
}
.inline-audio-player__container {
  display: flex;
  height: 50px;
  overflow: hidden;
}
.inline-audio-player__cta-holder {
  align-items: center;
  background-color: #ededed;
  display: flex;
}
.inline-audio-player__cta-container {
  align-items: center;
  float: left;
  width: 100%;
}
.inline-audio-player__cta-container.initialising * {
  cursor: initial;
  pointer-events: none;
  user-select: none;
}
.inline-audio-player__cta-container.initialising button {
  opacity: 0.6;
  float: left;
  width: 50px;
  background: #000;
  text-align: center;
}
.inline-audio-player__text {
  background: none;
  float: left;
  border: 0;
  color: #222;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  letter-spacing: -0.1px;
  line-height: 50px;
  margin-left: 12px;
  outline: inherit;
  padding: 0;
}
.inline-audio-player__text--offline {
  float: left;
  cursor: default;
  pointer-events: none;
}
.inline-audio-player__disclaimer {
  background-color: #e6711b;
  color: #fff;
  cursor: pointer;
  height: 25px;
  left: -110px;
  line-height: 12px;
  margin-left: 0;
  padding: 0 7px;
  position: relative;
  top: 25px;
  width: 66px;
}
.inline-audio-player__disclaimer:hover .inline-audio-player__arrow {
  transform: rotate(45deg);
}
.inline-audio-player__inner-arrow {
  color: #4d4d49;
  font-size: 10px;
}
.inline-audio-player__arrow-button {
  background-color: transparent;
  border: 0;
  margin-left: auto;
  outline: none;
  transition: all 0.5s ease;
}
.inline-audio-player__arrow-button__hide {
  display: none;
}
.inline-audio-player__arrow-button__open {
  transform: rotate(180deg);
}
.inline-audio-player__disclaimer-copy {
  color: #444;
  display: block;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
}
.inline-audio-player__arrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  top: -1px;
  transform: rotate(-45deg);
  transition: 0.4s;
}
.inline-audio-player__hidden {
  display: none;
}
.inline-audio-player .collapsible-container {
  color: #444;
  font-size: 16px;
  line-height: 24px;
  padding: 1rem 0;
}
.inline-audio-player .collapsible-container__hide {
  display: none;
}
.inline-audio-player .previous-button__inline-audio {
  margin-right: 1px;
}
.inline-audio-player__line {
  margin-top: 16px;
}
.inline-audio-player .previous-media-button {
  height: 50px;
  margin-right: 1px;
  width: 50px;
}
.inline-audio-player .previous-button__inline-audio {
  margin-right: 1px;
}
