@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  fill: #444;
  float: left;
  margin-left: 9px;
  height: 32px;
  width: 32px;
}
.spinner__image {
  animation: spin 1s linear infinite;
}
.spinner--worklife {
  fill: #8beed9;
}
.spinner--future {
  fill: #ffc857;
}
.spinner--culture {
  fill: #472479;
}
.spinner--travel {
  fill: #002856;
}
.spinner--earth {
  fill: #002856;
}
.spinner--white {
  fill: #fff;
}
.spinner--audio {
  height: 32px;
  width: 32px;
}
