.styled-line {
  background-color: #8beed9;
  height: 3px;
  width: 72px;
}
.styled-line--small {
  width: 45px;
}
.styled-line--medium {
  width: 135px;
}
.styled-line--large {
  height: 1px;
  width: auto;
}
.styled-line--height--large {
  height: 5px;
}
.styled-line--height--small {
  height: 1px;
}
.styled-line--xs-small {
  width: 25px;
}
.styled-line--worklife {
  background-color: #8beed9;
}
.styled-line--future {
  background-color: #ffc857;
}
.styled-line--culture {
  background-color: #472479;
}
.styled-line--earth {
  background-color: #002856;
}
.styled-line--travel {
  background-color: #002856;
}
.styled-line--white {
  background-color: #fff;
}
.styled-line--dark-grey {
  background-color: #6a6a6a;
}
.styled-line--light-grey {
  background-color: #4b4b4b;
}
.styled-line--grey {
  background-color: #dedede;
}
