.error-button {
  float: left;
  background-color: #000;
  border: 0;
  font-size: 22px;
  height: 50px;
  justify-content: center;
  outline: none;
  transition-duration: 0.6s;
  width: 50px;
  text-align: center;
}
.error-button .gelicon--alert {
  color: #ececec;
  line-height: 50px;
}
