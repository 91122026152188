.previous-media-button {
  align-items: center;
  background-color: #000;
  transition: background-color 0.4s ease-out;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 50px;
  justify-content: center;
  outline: none;
  transition-duration: 0.6s;
  width: 50px;
}

.previous-media-button:hover,
.previous-media-button:focus-visible {
  background-color: rgb(184, 0, 0);
}

.previous-media-button__icon--white svg {
  fill: #fff;
}
.previous-media-button span {
  height: 18px;
  width: 16px;
}
