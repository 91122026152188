.play-button__inline-audio,
.play-button__inline-video {
  border: 0;
  cursor: pointer;
  font-size: 16px;
  height: 49px;
  outline: none;
  /* transition-duration: 0.6s; */
  width: 100%;
  background: none;
  float: left;
}

.play-button__inline-audio .play-button__icon,
.play-button__inline-video .play-button__icon {
  float: left;
  background-color: #000;
  transition: background-color 0.4s ease-out;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.play-button__inline-audio:hover .play-button__icon,
.play-button__inline-audio:focus-visible .play-button__icon,
.play-button__inline-audio .play-button__icon:hover,
.play-button__inline-audio .play-button__icon:focus-visible {
  background-color: rgb(184, 0, 0);
}

.play-button__inline-audio .gelicon--play,
.play-button__inline-video .gelicon--play {
  color: #ececec;
  transition-duration: 0.6s;
}
.play-button__inline-audio--worklife .gelicon--play,
.play-button__inline-video--worklife .gelicon--play {
  color: #8beed9;
}
.play-button__inline-audio--travel .gelicon--play,
.play-button__inline-video--travel .gelicon--play {
  color: #002856;
}
.play-button__inline-audio--future .gelicon--play,
.play-button__inline-video--future .gelicon--play {
  color: #ffc857;
}
.play-button__inline-audio--culture .gelicon--play,
.play-button__inline-video--culture .gelicon--play {
  color: #472479;
}
.play-button__inline-audio--earth .gelicon--play,
.play-button__inline-video--earth .gelicon--play {
  color: #002856;
}
.play-button__inline-video:hover {
  background-color: #ececec;
}
.play-button__inline-video:hover .gelicon--play {
  color: #000;
}
.play-button__inline-audio {
  color: #fff;
  font-size: 22px;
  height: 50px;
  padding: 0;
}
.play-button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #5ae9cb;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  height: 80px;
  justify-content: center;
  outline: none;
  transition-duration: 0.6s;
  width: 80px;
}
.play-button:hover {
  color: #5ae9cb;
}
.play-button--white {
  border: 4px solid #fff;
}
.play-button--white:hover {
  color: #fff;
}
.play-button--white .gelicon--play {
  color: inherit;
}
.play-button--worklife {
  border: 2px solid #8beed9;
}
.play-button--worklife:hover {
  color: #8beed9;
}
.play-button--travel {
  border: 2px solid #002856;
}
.play-button--travel:hover {
  color: #002856;
}
.play-button--future {
  border: 2px solid #ffc857;
}
.play-button--future:hover {
  color: #ffc857;
}
.play-button--culture {
  border: 2px solid #472479;
}
.play-button--culture:hover {
  color: #472479;
}
.play-button--earth {
  border: 2px solid #002856;
}
.play-button--earth:hover {
  color: #002856;
}
.play-button--desktop {
  font-size: 30px;
  height: 76px;
  width: 76px;
}
.play-button--background-hover:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.play-button__hidden_element {
  display: none;
}
