@font-face {
  font-family: "BBCReithSans";
  font-style: normal;
  font-weight: normal;
  src: url("https://static.files.bbci.co.uk/fonts/reith/2.512/BBCReithSans_W_Rg.woff2");
}

body {
  margin: 0;
}

body,
.b-font-family-serif {
  font-family: BBCReithSans;
}

.inline-audio {
  display: block;
  padding-top: 8px;
}

.article__author-unit {
  padding: 0;
  margin-bottom: 0;
}

@font-face {
  font-family: "gelicons-media";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/gelicons-media.eot"),
    url("./fonts/gelicons-media.ttf") format("truetype");
}

@font-face {
  font-family: "gelicons-core";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/gelicons-core.eot"),
    url("./fonts/gelicons-core.ttf") format("truetype");
}

.gelicon {
  font-family: "gelicons-core";
}
.gelicon,
.gelicon::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal !important;
  font-weight: normal !important;
  speak: none;
  text-decoration: none !important;
}
.gelicon--leading::before {
  margin-right: 8px;
}
.gelicon--trailing::before {
  margin-left: 8px;
}
.gelicon--core {
  font-family: "gelicons-core" !important;
}
/* Media icons */
.gelicon--menu,
.gelicon--live,
.gelicon--subtitles,
.gelicon--ws,
.gelicon--sd,
.gelicon--hd,
.gelicon--bsl,
.gelicon--ad,
.gelicon--3d,
.gelicon--tv,
.gelicon--radio,
.gelicon--mobile,
.gelicon--desktop,
.gelicon--game,
.gelicon--tablet,
.gelicon--quiz,
.gelicon--guidance,
.gelicon--record,
.gelicon--listen,
.gelicon--play,
.gelicon--pause,
.gelicon--rewind,
.gelicon--forward,
.gelicon--restart,
.gelicon--image,
.gelicon--document,
.gelicon--slideshow,
.gelicon--activity,
.gelicon--360,
.gelicon--podcast,
.gelicon--newsletter,
.gelicon--rss,
.gelicon--fullscreen,
.gelicon--expand,
.gelicon--popout,
.gelicon--mute,
.gelicon--music-on,
.gelicon--music-off,
.gelicon--windows,
.gelicon--apple,
.gelicon--interactive {
  font-family: "gelicons-media" !important;
}
/* Begin Icons */
.gelicon--menu::before {
  content: "\e900";
}
.gelicon--live::before {
  content: "\e100";
}
.gelicon--subtitles::before {
  content: "\e101";
}
.gelicon--ws::before {
  content: "\e102";
}
.gelicon--sd::before {
  content: "\e103";
}
.gelicon--hd::before {
  content: "\e104";
}
.gelicon--bsl::before {
  content: "\e105";
}
.gelicon--ad::before {
  content: "\e106";
}
.gelicon--3d::before {
  content: "\e107";
}
.gelicon--tv::before {
  content: "\e108";
}
.gelicon--radio::before {
  content: "\e109";
}
.gelicon--mobile::before {
  content: "\e10a";
}
.gelicon--desktop::before {
  content: "\e10b";
}
.gelicon--game::before {
  content: "\e10c";
}
.gelicon--tablet::before {
  content: "\e10d";
}
.gelicon--quiz::before {
  content: "\e10e";
}
.gelicon--guidance::before {
  content: "\e10f";
}
.gelicon--record::before {
  content: "\e110";
}
.gelicon--listen::before {
  content: "\e111";
}
.gelicon--play::before {
  content: "\e112";
}
.gelicon--pause::before {
  content: "\e113";
}
.gelicon--rewind::before {
  content: "\e114";
}
.gelicon--forward::before {
  content: "\e115";
}
.gelicon--restart::before {
  content: "\e116";
}
.gelicon--image::before {
  content: "\e117";
}
.gelicon--document::before {
  content: "\e118";
}
.gelicon--slideshow::before {
  content: "\e119";
}
.gelicon--activity::before {
  content: "\e11a";
}
.gelicon--360::before {
  content: "\e11b";
}
.gelicon--podcast::before {
  content: "\e11c";
}
.gelicon--newsletter::before {
  content: "\e11d";
}
.gelicon--rss::before {
  content: "\e11e";
}
.gelicon--fullscreen::before {
  content: "\e11f";
}
.gelicon--expand::before {
  content: "\e120";
}
.gelicon--popout::before {
  content: "\e121";
}
.gelicon--mute::before {
  content: "\e122";
}
.gelicon--music-on::before {
  content: "\e123";
}
.gelicon--music-off::before {
  content: "\e124";
}
.gelicon--windows::before {
  content: "\e125";
}
.gelicon--apple::before {
  content: "\e126";
}
.gelicon--interactive::before {
  content: "\e900";
}

.gelicon--alert {
  font-family: "gelicons-core" !important;
}

.gelicon--alert::before {
  content: "\e012";
}
